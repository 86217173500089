import React, { useEffect, useState } from 'react';
import HairdresserCTA from './HairdresserCTA';

const HairdressersList = ({ hairdressersList, closePopup, mobileChangeColumn, resetNfWidth }) => {

  const [opened, setOpened] = useState();

  const handlerClose = (ev) => {
    //if (ev.target.tagName === "SECTION") {
    setOpened(false);

    let timeout = window.setTimeout(() => {
      closePopup();

      return () => {
        clearTimeout(timeout);
      }
    }, 300)
    //}

  }

  useEffect(() => {
    if (!opened) {
      let timeout = window.setTimeout(() => {
        setOpened(true);
        return () => {
          clearTimeout(timeout);
        }
      }, 100)
    }
  }, [])

  return (
    <section className={opened ? "hdslist-container visible" : "hdslist-container"} onClick={handlerClose}>
      <div className='hdslist-content'>
        <h2>Choisir un membre de l'équipe :</h2>
        <ul>
          {
            hairdressersList?.map((hd, index) =>
              <HairdresserCTA hairdresser={hd} hdIndex={index} key={index} mobileChangeColumn={mobileChangeColumn} handlerClose={handlerClose} resetNfWidth={resetNfWidth} />
            )
          }
        </ul>
      </div>
    </section>
  );
};

export default HairdressersList;
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const NotifScreen = ({ message, closeScreen }) => {

  const [opened, setOpened] = useState(true);

  useEffect(() => {
    let timeout;
    if (opened) {
      timeout = window.setTimeout(() => {
        setOpened(false);

        return () => {
          clearTimeout(timeout);
        }
      }, 2000)
    }
    else {
      timeout = window.setTimeout(() => {
        closeScreen();

        return () => {
          clearTimeout(timeout);
        }
      }, 600)
    }

  }, [opened])

  return (
    <section className={opened ? "notif-screen" : "notif-screen closed"}>
      <div className="success-checkmark">
        <div className="check-icon">
          <span className="icon-line line-tip"></span>
          <span className="icon-line line-long"></span>
          <div className="icon-circle"></div>
          <div className="icon-fix"></div>
        </div>
      </div>
      <p>{message}</p>
    </section>
  );
};

export default NotifScreen;
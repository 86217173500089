import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';

const Postit = ({ postit, mousePos, touchPos, postitIndex, closePostit }) => {

  const [posX, setPosX] = useState(postit.x);
  const [posY, setPosY] = useState(postit.y);
  const [oldPosX, setOldPosX] = useState();
  const [oldPosY, setOldPosY] = useState();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isTouchDown, setIsTouchDown] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(postit.display);

  const textarea = useRef();

  const postitStyle = {
    right: posX + "px",
    top: posY + "px",
    display: isDisplayed
  }

  const handlerMouseDown = (ev) => {
    setIsMouseDown([ev.clientX, ev.clientY]);
    setOldPosX(posX);
    setOldPosY(posY);
  }

  const handlerMouseUp = (ev) => {
    setIsMouseDown(false);
  }

  const handlerTouchStart = (ev) => {
    setIsTouchDown([ev.touches[0].clientX, ev.touches[0].clientY]);
    setOldPosX(posX);
    setOldPosY(posY);
  }

  const handlerTouchEnd = (ev) => {
    setIsTouchDown(false);
  }

  const handlerClose = () => {
    setIsDisplayed("none");
    closePostit(postit.id);
    let allPostitsLocal = localStorage.getItem("postits");

    allPostitsLocal = JSON.parse(allPostitsLocal);

    let newPostitsLocal = allPostitsLocal.filter(postitLocal => postitLocal.id !== postit.id);

    localStorage.setItem("postits", JSON.stringify(newPostitsLocal));

  }

  const handlerInput = (ev) => {
    let allPostitsLocal = localStorage.getItem("postits");

    allPostitsLocal = JSON.parse(allPostitsLocal);

    let newPostitsLocal = allPostitsLocal.map((postitLocal, index) => {
      if (postitLocal.id === postit.id) {
        return ({
          id: postit.id,
          x: posX,
          y: posY,
          display: isDisplayed,
          content: textarea.current.value
        })
      }

      return postitLocal;
    })

    localStorage.setItem("postits", JSON.stringify(newPostitsLocal));
  }

  useEffect(() => {
    if (mousePos?.length === 2 && isMouseDown !== false) {
      let tmpPosX = oldPosX + (isMouseDown[0] - mousePos[0]);
      let tmpPosY = oldPosY - (isMouseDown[1] - mousePos[1])
      setPosX(tmpPosX);
      setPosY(tmpPosY);

      let allPostitsLocal = localStorage.getItem("postits");

      allPostitsLocal = JSON.parse(allPostitsLocal);

      let newPostitsLocal = allPostitsLocal.map((postitLocal, index) => {
        if (postitLocal.id === postit.id) {
          return ({
            id: postit.id,
            x: tmpPosX,
            y: tmpPosY,
            display: isDisplayed,
            content: textarea.current.value
          })
        }

        return postitLocal;
      })

      localStorage.setItem("postits", JSON.stringify(newPostitsLocal));
    }
    else if (touchPos?.length === 2 && isTouchDown !== false) {
      let tmpPosX = oldPosX + (isTouchDown[0] - touchPos[0]);
      let tmpPosY = oldPosY - (isTouchDown[1] - touchPos[1])
      setPosX(tmpPosX);
      setPosY(tmpPosY);

      let allPostitsLocal = localStorage.getItem("postits");

      allPostitsLocal = JSON.parse(allPostitsLocal);

      let newPostitsLocal = allPostitsLocal.map((postitLocal, index) => {
        if (postitLocal.id === postit.id) {
          return ({
            id: postit.id,
            x: tmpPosX,
            y: tmpPosY,
            display: isDisplayed,
            content: textarea.current.value
          })
        }

        return postitLocal;
      })

      localStorage.setItem("postits", JSON.stringify(newPostitsLocal));
    }
  }, [mousePos, touchPos])

  return (
    <div className='postit' style={postitStyle}>
      <div className='postit-header' onMouseDown={handlerMouseDown} onMouseUp={handlerMouseUp} onTouchStart={handlerTouchStart} onTouchEnd={handlerTouchEnd}>
        <button onClick={handlerClose}><FontAwesomeIcon icon={faXmark} /></button>
      </div>
      <textarea maxLength={255} defaultValue={postit.content} onInput={handlerInput} ref={textarea}>
      </textarea>
    </div>
  );
};

export default Postit;
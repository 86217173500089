import React from 'react';

const Divide = ({ presta, hd, end }) => {
  return (
    <>
      {presta} par {hd}
      {end ? "" : " ; "}
    </>
  );
};

export default Divide;
import { faArrowLeft, faArrowRight, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import Month from './Month';
import Week from './Week';

const Calendar = ({ closeCalendar, changeDate, dateNow }) => {

  const today = new Date(dateNow("php"));
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [year, setYear] = useState(today.getFullYear());
  const [quarter, setQuarter] = useState();
  const [schedule, setSchedule] = useState();
  const [opened, setOpened] = useState(false);

  const getSchedule = () => {
    axios.get("https://connivence-salon.net/api/resaplan/quarter.php?quarter=" + quarter + "&year=" + year)
      .then((res) => {
        if (res.data.success) {
          setSchedule(res.data.content);
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }
      })
  }


  const handlerClose = (ev) => {
    if (ev.target.tagName === "SECTION") {
      setOpened(false);

      let timeout = window.setTimeout(() => {
        closeCalendar();

        return () => {
          clearTimeout(timeout);
        }
      }, 300)
    }

  }

  const handlerGoLeft = (ev) => {
    if (quarter === 1) {
      setQuarter(4);
      let newYear = year - 1;
      setYear(newYear);
    }
    else {
      let newQuarter = quarter - 1;
      setQuarter(newQuarter);
    }
  }

  const handlerGoRight = (ev) => {
    if (quarter === 4) {
      setQuarter(1);
      let newYear = year + 1;
      setYear(newYear);
    }
    else {
      let newQuarter = quarter + 1;
      setQuarter(newQuarter);
    }

  }

  const handlerToday = () => {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    today = yyyy + '-' + mm + '-' + dd;
    changeDate(today);
    closeCalendar();
  }

  useEffect(() => {
    if (!schedule && !quarter) {
      switch (month) {
        case 1:
        case 2:
        case 3:
          setQuarter(1);
          break;
        case 4:
        case 5:
        case 6:
          setQuarter(2);
          break;
        case 7:
        case 8:
        case 9:
          setQuarter(3);
          break;
        case 10:
        case 11:
        case 12:
          setQuarter(4);
          break;
        default:
          setQuarter(1);
          break;
      }

      let timeout = window.setTimeout(() => {
        setOpened(true);
        return () => {
          clearTimeout(timeout);
        }
      }, 100)
    }
    if (quarter) {
      getSchedule();
    }

  }, [quarter, year])
  return (
    <section className={opened ? "calendar-container visible" : "calendar-container"} onClick={handlerClose}>
      <div className='calendar-content'>
        <h2>
          <button onClick={handlerGoLeft}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          T{quarter} {year}
          <button onClick={handlerGoRight}>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </h2>
        {schedule ?
          <div className='articles-container'>
            {
              schedule?.map((row, index) => (
                <Month changeDate={changeDate} key={index} month={row} closeCalendar={closeCalendar} />
              ))
            }
          </div>
          :
          <div className='loader'>
            <PulseLoader
              color={'#191919'}
              loading={true}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }
        <button className='today' onClick={handlerToday}><FontAwesomeIcon icon={faClockRotateLeft} />Aujourd'hui</button>
      </div>
    </section>
  );
};

export default Calendar;
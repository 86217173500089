import { faArrowLeftLong, faBan, faCrown, faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

const EditPopup = ({ appoint, closePopup, hairdressersList, prestas, getDatas, salon, openNotifScreen }) => {

  const [opened, setOpened] = useState(false);
  const [defaultTime, setDefaultTime] = useState("");
  const [appointDivides, setAppointDivides] = useState(appoint.divides);
  const [sectionOpened, setSectionOpened] = useState("default");

  const dayInput = useRef();
  const hourInput = useRef();
  const durationInput = useRef();
  const hdInput = useRef();
  const prestaInput = useRef();

  const handlerClose = (ev) => {
    if (ev.target.tagName === "SECTION") {
      setOpened(false);

      let timeout = window.setTimeout(() => {
        closePopup();

        return () => {
          clearTimeout(timeout);
        }
      }, 300)
    }

  }

  const handlerRemove = (ev) => {
    ev.preventDefault();
    if (window.confirm("Voulez-vous vraiment annuler le rendez-vous de " + appoint.prenom + " " + appoint.nom + " ?")) {
      axios.get("https://connivence-salon.net/api/resaplan/remove.php?id=" + appoint.rdv_id)
        .then((res) => {
          if (res.data.success) {
            getDatas();
            closePopup();
          }
          else if (res.data.error) {
            console.log(res.data.error);
          }
        })
    }
  }

  const setDurationValue = (min) => {
    let thisHour = Math.floor(min / 60);
    thisHour = thisHour < 10 ? "0" + thisHour : thisHour;
    let thisMinute = min % 60;
    thisMinute = thisMinute < 10 ? "0" + thisMinute : thisMinute;
    durationInput.current.value = thisHour + ":" + thisMinute;
  }

  const setMinutesValue = (duration) => {
    let thisHour = parseInt(duration.split(":")[0]);
    let thisMinute = parseInt(duration.split(":")[1]);

    return String(thisHour * 60 + thisMinute);
  }

  const handlerChange = (ev) => {
      let thisPresta = prestas.filter(presta => presta.id === prestaInput.current.value);
      let thisDuree = thisPresta[0].duree;
      if (ev.target === prestaInput.current) {
        setDurationValue(thisDuree);
      }
    if(sectionOpened === "default"){
        setAppointDivides(oldArray => oldArray.map((divide, index) =>
          index === 0
            ? [prestaInput.current.value, hdInput.current.value, setMinutesValue(durationInput.current.value)]
            : [...divide]
        ));
    }
    
  }

  const handlerDuration = (ev) => {
    if(sectionOpened === "default"){
      setAppointDivides(oldArray => oldArray.map((divide, index) =>
        index === 0
          ? [prestaInput.current.value, hdInput.current.value, setMinutesValue(durationInput.current.value)]
          : [...divide]
      ));
    }
    
  }

  const handlerEdit = (ev) => {
    ev.preventDefault();
    if (window.confirm("Voulez-vous vraiment modifier le rendez-vous de " + appoint.prenom + " " + appoint.nom + " ?")) {
      axios.get("https://connivence-salon.net/api/resaplan/remove.php?id=" + appoint.rdv_id + "&editing=1")
        .then((res) => {
          if (res.data.success) {
            let ficheId = res.data.success.fiche;
            let requestDivides = [];

            for (let i = 0; i < appointDivides.length; i++) {
              requestDivides.push(appointDivides[i].join(","));
            }

            let parent = appoint.parent === null ? 0 : appoint.parent;
            
            let requestUrl = "day=" + dayInput.current.value + "&hour=" + hourInput.current.value + "&salon=" + salon.id + "&presta=" + prestaInput.current.value + "&hairdresser=" + hdInput.current.value + "&client=" + ficheId + "&tranche=" + hourInput.current.value.split(":")[1] + "&divides=" + requestDivides.join("D") + "&recurrence=" + appoint.recurrent.join("R") + "&custom=" + durationInput.current.value + "&parent=" + parent + "&editing=1&forced=0&mail=";
            axios.get("https://connivence-salon.net/api/resaplan/create.php?" + requestUrl)
              .then((resBis) => {
                if (resBis.data.success) {
                  if (resBis.data.success === "Slot unavailable") {

                  }
                  else {
                    openNotifScreen("Le rendez-vous de " + appoint.nom.toUpperCase() + " " + appoint.prenom + " a bien été modifié !");
                    getDatas();
                    closePopup();
                  }

                }
                else if (resBis.data.error) {
                  console.log(resBis.data.error);
                }
              })
          }
          else if (res.data.error) {
            console.log(res.data.error);
          }
        })
    }
  }

  const handlerOpenAddForm = (ev) => {
    ev.preventDefault();
    setSectionOpened("addForm");
    prestaInput.current.value = prestas[0].id;
    hdInput.current.value = hairdressersList[0].id;
    setDurationValue(prestas[0].duree);
  }
  const handlerCloseAddForm = (ev) => {
    ev.preventDefault();
    setSectionOpened("default");
    ev.target.blur();
    prestaInput.current.value = appoint.presta_id;
    hdInput.current.value = appoint.coiffeur_id;
    durationInput.current.value = defaultTime;
  }

  const handlerAddDivide = (ev) => {
    ev.preventDefault();
    if (window.confirm("Voulez-vous vraiment ajouter une prestation au rendez-vous de " + appoint.prenom + " " + appoint.nom + " ?")) {
      axios.get("https://connivence-salon.net/api/resaplan/remove.php?id=" + appoint.rdv_id + "&editing=1")
        .then((res) => {
          if (res.data.success) {
            let ficheId = res.data.success.fiche;
            let requestDivides = [];

            for (let i = 0; i < appointDivides.length; i++) {
              requestDivides.push(appointDivides[i].join(","));
            }

            let newDivide = [prestaInput.current.value, hdInput.current.value, setMinutesValue(durationInput.current.value)];

            requestDivides.push(newDivide.join(","));

            let parent = appoint.parent === null ? 0 : appoint.parent;
            let requestUrl = "day=" + appoint.date.split(" ")[0] + "&hour=" + appoint.date.split(" ")[1] + "&salon=" + salon.id + "&presta=" + prestas[0].id + "&hairdresser=" + hairdressersList[0].id + "&client=" + ficheId + "&tranche=" + appoint.date.split(" ")[1].split(":")[1] + "&divides=" + requestDivides.join("D") + "&recurrence=" + appoint.recurrent.join("R") + "&custom=" + durationInput.current.value + "&parent=" + parent + "&editing=1&forced=0&mail=";
            axios.get("https://connivence-salon.net/api/resaplan/create.php?" + requestUrl)
              .then((resBis) => {
                if (resBis.data.success) {
                  if (resBis.data.success === "Slot unavailable") {

                  }
                  else {
                    openNotifScreen("Le rendez-vous de " + appoint.nom.toUpperCase() + " " + appoint.prenom + " a bien été modifié !");
                    getDatas();
                    closePopup();
                  }

                }
                else if (resBis.data.error) {
                  console.log(resBis.data.error);
                }
              })
          }
          else if (res.data.error) {
            console.log(res.data.error);
          }
        })
    }
    

  }

  useEffect(() => {
    if (!opened) {
      let timeout = window.setTimeout(() => {
        setOpened(true);
        return () => {
          clearTimeout(timeout);
        }
      }, 100)

      let durationHours;
      let durationMinutes;

      if (appoint.custom !== null) {

        let customMinutes = appoint.custom / 60;

        durationHours = Math.floor(customMinutes) < 10 ? "0" + Math.floor(customMinutes) : Math.floor(customMinutes);

        durationMinutes = ((customMinutes - Math.floor(customMinutes)) * 60) < 10 ? "0" + (customMinutes - Math.floor(customMinutes)) * 60 : (customMinutes - Math.floor(customMinutes)) * 60;

        setDefaultTime(durationHours + ":" + durationMinutes)
      }
      else {
        durationHours = Math.floor(appoint.minutes) < 10 ? "0" + Math.floor(appoint.minutes) : Math.floor(appoint.minutes);

        durationMinutes = ((appoint.minutes - Math.floor(appoint.minutes)) * 60) < 10 ? "0" + (appoint.minutes - Math.floor(appoint.minutes)) * 60 : (appoint.minutes - Math.floor(appoint.minutes)) * 60;

        setDefaultTime(durationHours + ":" + durationMinutes)
      }
    }
  }, [])

  return (
    <section className={opened ? "edit-container visible" : "edit-container"} onClick={handlerClose}>
      <div className='edit-content'>
        <h2>Gérer le rendez-vous de {appoint.nom.toUpperCase()} {appoint.prenom}</h2>
        {
          appoint.telephone && appoint.telephone.length > 0 ?
            <p className='discreet-text'>{
              appoint.telephone[0] === "+" ? appoint.telephone : appoint.telephone.replaceAll(" ", "").replace(/(.{2})(?=.)/g, "$1 ")
            }</p>
            :
            <p className='discreet-text'>
              Pas de numéro de téléphone renseigné
            </p>
        }
        <form className='appoint-form'>
        {sectionOpened === "default" ? 
          <div className='form-element'>
            <label htmlFor="create-date">Date et heure du rendez-vous :</label>
            <div className='form-inputs'>
              <input type="date" id="create-date" name="create-date" defaultValue={appoint.date.split(" ")[0]} ref={dayInput} />
              <input type="time" id="create-hour" name="create-hour" defaultValue={appoint.date.split(" ")[1]} step="900" max="23:30" min="05:00" ref={hourInput} />
            </div>
          </div>
          :
          <h3>Ajouter une prestation</h3>
        }
          <div className='form-element'>
            <label htmlFor="create-presta">Prestation :</label>
            <select defaultValue={appoint.presta_id} id="create-presta" name="create-presta" ref={prestaInput} onChange={handlerChange}>
              {
                prestas?.map((presta, index) => (
                  <option key={index} value={presta.id}>{presta.prestation}</option>
                ))
              }
            </select>
          </div>
          <div className='form-element'>
            <label htmlFor="create-hairdresser">Coiffeur :</label>
            <select defaultValue={appoint.coiffeur_id} id="create-hairdresser" name="create-hairdresser" ref={hdInput} onChange={handlerChange}>
              {
                hairdressersList?.map((hairdresser, index) => (
                  <option key={index} value={hairdresser.id}>{hairdresser.prenom} {hairdresser.nom[0]}.</option>
                ))
              }
            </select>
          </div>
          <div className='form-element'>
            <label htmlFor="create-duration">Durée de la prestation :</label>
            <div className='form-inputs'>
              <input type="time" id="create-duration" name="create-duration" defaultValue={defaultTime} step="900" max="10:00" min="00:00" ref={durationInput} onInput={handlerDuration} />
            </div>
          </div>

          <div className='form-actions'>
            {
              sectionOpened === "default" ? 
            <>
              <button className='edit-appoint' onClick={handlerEdit}>
                Modifier
                <span className='arrow'>
                  <FontAwesomeIcon icon={faPen} />
                </span>
              </button>
              <button className='remove-appoint' onClick={handlerRemove}>
                Annuler
                <span className='arrow'>
                  <FontAwesomeIcon icon={faBan} />
                </span>
              </button>
            </>
            :
            <>
            <button className='close-button' onClick={handlerCloseAddForm}>
              <span className='arrow'>
                <FontAwesomeIcon icon={faArrowLeftLong} />
              </span>
              Retour
            </button>
            <button className='add-button' onClick={handlerAddDivide}>
              <span className='arrow'>
                <FontAwesomeIcon icon={faPlus} />
              </span>
              Ajouter
            </button>
            </>
          }
          </div>

          {appoint.parent || sectionOpened !== "default" ?
          ""
          :
          <>
            <p className='parent-appoint'><FontAwesomeIcon icon={faCrown} /> Cette prestation est la première de ce rendez-vous.</p>
            <button className='add-divide' onClick={handlerOpenAddForm}>
              <span className='arrow'>
                <FontAwesomeIcon icon={faPlus} />
              </span>
              Ajouter une prestation
            </button>
          </>
          }
        </form>
      </div>
    </section>
  );
};

export default EditPopup;
import React, { useEffect, useRef } from 'react';

const Hour = ({ hour, nowHour, scrollToHour, lastElement, firstAppointment, lastAppointment }) => {

  const thisHourRef = useRef();

  useEffect(() => {
    if (lastElement) {
      scrollToHour(thisHourRef.current);
    }

  }, [])

  return (
    <li ref={thisHourRef} id={"hour-" + parseInt(hour)} className='planning-hour-legends' style={hour < firstAppointment || hour > lastAppointment ? { display: "none" } : {}}>
      <div className='planning-subrow'>
        <p className='hour'>
          {hour}:00
        </p>
      </div>
      <div className='planning-subrow'>
        <p className='hour'>
          {hour}:30
        </p>
      </div>
    </li>
  );
};

export default Hour;
import React from 'react';
import Day from './Day';

const Week = ({ week, changeDate, closeCalendar }) => {
  return (
    <li>
      <ul className='calendar-row'>
        {
          week?.map((day, index) => (
            <Day changeDate={changeDate} key={index} day={day} closeCalendar={closeCalendar} />
          ))
        }
      </ul>

    </li>
  );
};

export default Week;
import { faAnglesLeft, faAnglesRight, faArrowLeftLong, faCalendarDay, faCalendarDays, faClockRotateLeft, faNoteSticky, faRotateRight, faUserClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GridLoader } from 'react-spinners';
import Calendar from '../components/Calendar';
import Column from '../components/Column';
import Hour from '../components/Hour';
import NotifScreen from '../components/NotifScreen';
import Postit from '../components/Postit';
import Recurrents from '../components/Recurrents';

const Home = () => {

  const hours = [
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23"
  ]

  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche"
  ]

  const { salonId } = useParams();

  const [dateActive, setDateActive] = useState(new Date());
  const [datas, setDatas] = useState(null);
  const [salon, setSalon] = useState(null);
  const [prestas, setPrestas] = useState();
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [recurrentsOpened, setRecurrentsOpened] = useState(false);
  const [hairdressers, setHairdressers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [notifScreen, setNotifScreen] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [cooldownTime, setCooldownTime] = useState();
  const [resizingElement, setResizingElement] = useState(null);
  const [oldMousePos, setOldMousePos] = useState(0);
  const [appointHeight, setAppointHeight] = useState(0);
  const [appointDuration, setAppointDuration] = useState(0)
  const [resizeCoeff, setResizeCoeff] = useState(0);
  const [calendarPosition, setCalendarPosition] = useState(0);
  const [calendarScrollTop, setCalendarScrollTop] = useState(0);
  const [mobileColumnIndex, setMobileColumnIndex] = useState(0);
  const [postits, setPostits] = useState([]);
  const [mousePos, setMousePos] = useState([]);
  const [touchPos, setTouchPos] = useState([]);
  const [postitsInitialized, setPostitsInitalized] = useState(false);
  const [firstAppointment, setFirstAppointment] = useState(9);
  const [lastAppointment, setLastAppointment] = useState(19);

  const nowHour = parseInt(dateActive.getHours());


  const navigate = useNavigate();

  const closeCalendar = () => {
    setCalendarOpened(false);
  }

  const closeRecurrents = () => {
    setRecurrentsOpened(false);
  }

  const resize = (ev, appoint) => {
    let el = ev.target.parentNode;
    appoint.elementId = el.id;
    let planningSubrow = el.parentNode.querySelector(".planning-subrow");
    setResizingElement(appoint);
    setOldMousePos(ev.clientY);
    setAppointHeight(el.offsetHeight);
    setAppointDuration(el.dataset.duration);
    setResizeCoeff(planningSubrow.offsetHeight / 2);
  }

  const handlerMouseUp = (ev) => {
    if (resizingElement !== null && document.getElementById(resizingElement.elementId)) {
      let el = document.getElementById(resizingElement.elementId);
      let appoint = resizingElement;
      let appointDivides = resizingElement.divides;
      let newDuration = parseInt(el.dataset.duration);
      if (newDuration < 15) {
        newDuration = 15;
      }

      axios.get("https://connivence-salon.net/api/resaplan/remove.php?id=" + appoint.rdv_id + "&editing=1")
        .then((res) => {
          if (res.data.success) {
            let ficheId = res.data.success.fiche;
            appointDivides[0][2] = newDuration;

            let requestDivides = [];

            for (let i = 0; i < appointDivides.length; i++) {
              requestDivides.push(appointDivides[i].join(","));
            }

            let parent = appoint.parent === null ? 0 : appoint.parent;

            let thisHour = appoint.date.split(" ")[1];
            let requestUrl = "day=" + appoint.date.split(" ")[0] + "&hour=" + thisHour + "&salon=" + salon.id + "&presta=" + appoint.presta_id + "&hairdresser=" + appoint.coiffeur_id + "&client=" + ficheId + "&tranche=" + thisHour.split(":")[1] + "&divides=" + requestDivides.join("D")  + "&recurrence=" + appoint.recurrent.join("R") + "&custom=" + newDuration + "&parent=" + parent + "&editing=1&forced=0&mail=";
            axios.get("https://connivence-salon.net/api/resaplan/create.php?" + requestUrl)
              .then((resBis) => {
                if (resBis.data.success) {
                  if (resBis.data.success === "Slot unavailable") {

                  }
                  else {
                    getDatas();
                  }

                }
                else if (resBis.data.error) {
                  console.log(resBis.data.error);
                }
              })
          }
          else if (res.data.error) {
            console.log(res.data.error);
          }
        })

      setResizingElement(null);
    }
  }

  function isTouchDevice() {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
  }

  const setCalPos = (value) => {
    setCalendarPosition(value);
  }

  const getCalPos = () => {
    return calendarPosition;
  }

  const dateNow = (format) => {
    const today = dateActive;
    let dayWeek = days[today.getDay() - 1];
    if (!dayWeek) {
      dayWeek = days[6];
    }
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    if (format === "php") {
      return (yyyy + '-' + mm + '-' + dd);
    }
    else if (format === "beauty") {
      return (dayWeek + " " + dd + "/" + mm + "/" + yyyy);
    }

  }

  const changeDate = (dateTmp) => {
    setDateActive(new Date(dateTmp));
    sessionStorage.setItem('date', dateTmp);
    getDatas();
  }

  const goToToday = () => {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    today = yyyy + '-' + mm + '-' + dd;
    changeDate(today);
  }

  const shortcutToDay = (bonus) => {

    if(datas.length > 0){
      let dateNow = dateActive

      dateNow.setDate(dateNow.getDate() + bonus);

      let dd = dateNow.getDate();

      let mm = dateNow.getMonth() + 1;
      const yyyy = dateNow.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }

      let newDay = yyyy + '-' + mm + '-' + dd;

      changeDate(newDay);
    }

  }

  const mobileChangeColumn = (newIndex) => {
    setMobileColumnIndex(newIndex);
  }

  const getDatas = () => {

    let date;

    if (sessionStorage.getItem("date")) {
      date = sessionStorage.getItem("date");
    }
    else {
      date = dateNow("php");
    }

    setDatas();

    axios.get("https://connivence-salon.net/api/resaplan/day.php?date=" + date + "&salon=" + salon?.id)
      .then((res) => {
        if (res.data.success) {
          setDatas(res.data.content);
          let tmpArray = [];
          let tmpFirstAppointment = 9;
          let tmpLastAppointment = 19;
          for (let i = 0; i < res.data.content.length; i++) {
            tmpArray.push({
              id: res.data.content[i].id,
              prenom: res.data.content[i].prenom,
              nom: res.data.content[i].nom,
            });

            setHairdressers(tmpArray);

            const arrayAppoints = res.data.content[i].slots.filter((apt) => apt.appoints.length > 0);

            if (tmpFirstAppointment > parseInt(arrayAppoints[0]?.full_hour)) {
              tmpFirstAppointment = parseInt(arrayAppoints[0]?.full_hour);
            }

            if (tmpLastAppointment < parseInt(arrayAppoints[arrayAppoints.length - 1]?.full_hour)) {
              tmpLastAppointment = parseInt(parseInt(arrayAppoints[arrayAppoints.length - 1]?.full_hour));
            }

            setFirstAppointment(tmpFirstAppointment);
            setLastAppointment(tmpLastAppointment);

          }

          setCooldownTime(Date.now());
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }
      })
  }

  const checkSalon = () => {
    axios.get("https://connivence-salon.net/api/resaplan/codes.php")
      .then((res) => {
        if (res.data.success) {

          let salonDatas = res.data.content.filter((obj) => obj.access === salonId);

          if (salonDatas.length === 0) {
            let timeout = window.setTimeout(() => {
              navigate("/");

              return () => {
                clearTimeout(timeout);
              }
            }, 1500)
          }
          else {
            setSalon(salonDatas[0]);
          }

        }
        else if (res.data.error) {
          console.log(res.data.error);
        }
      })
  }

  const openNotifScreen = (message) => {
    setNotifMessage(message);
    setNotifScreen(true);
  }

  const closeNotifScreen = () => {
    setNotifScreen(false);
  }

  const scrollToHour = (hourInfos) => {
    const hourHeight = parseInt(hourInfos.offsetHeight);
    const namesHeight = hourInfos.parentNode.querySelector("li").offsetHeight;

    if (nowHour >= parseInt(hours[0]) && nowHour <= parseInt(hours[hours.length - 1])) {
      const coeff = nowHour - parseInt(hours[0]);
      document.querySelector(".planning-column").scrollTo("0", (coeff * hourHeight) + namesHeight);
      setCalendarScrollTop((coeff * hourHeight) + namesHeight);
    }
  }

  const handlerMove = (ev) => {
    if (Math.floor((Date.now() - cooldownTime) / 1000) >= 600 && ev.target.classList.contains("planning-subrow")) {
      getDatas();
    }

    if (resizingElement !== null && document.getElementById(resizingElement.elementId)) {
      let change = (oldMousePos - ev.clientY) < 0 ? Math.ceil((oldMousePos - ev.clientY) / resizeCoeff) : Math.floor((oldMousePos - ev.clientY) / resizeCoeff);
      let el = document.getElementById(resizingElement.elementId);
      el.style.height = appointHeight - (change * resizeCoeff) - (change * 0.5) + "px";
      el.dataset.duration = appointDuration - (change * 15);
      let newDuration = appointDuration - (change * 15);
      if (newDuration < 45 && el.querySelector(".visible")) {
        el.querySelector(".visible").className = "hidden";
        el.querySelector(".ceiling").className = "quirky";
      }
      else if (newDuration > 30 && el.querySelector(".hidden")) {
        el.querySelector(".hidden").className = "visible";
        el.querySelector(".quirky").className = "ceiling";
      }
    }

    setMousePos([ev.clientX, ev.clientY]);

  }

  const handlerTouchMove = (ev) => {
    setTouchPos([ev.touches[0].clientX, ev.touches[0].clientY]);
  }

  const addPostit = () => {
    let postitsList = postits.filter(postit => postit.display === "flex");
    if (postitsList.length < 5) {
      let unicId = Date.now() / 100000;
      setPostits(previousState => ([...previousState, {
        id: unicId,
        x: 100,
        y: 100,
        content: "",
        display: "flex"
      }]
      ));

      let allPostitsLocal;

      if (localStorage.getItem("postits")) {
        allPostitsLocal = localStorage.getItem("postits");
      }
      else {
        allPostitsLocal = "[]";
      }

      let allPostitsArray = JSON.parse(allPostitsLocal);

      allPostitsArray.push({
        id: unicId,
        x: 100,
        y: 100,
        content: "",
        display: "flex"
      });

      localStorage.setItem("postits", JSON.stringify(allPostitsArray));

    }
    else {
      window.alert("Vous avez atteint le nombre maximum de post-its sur votre écran.")
    }
  }

  const closePostit = (ind) => {
    setPostits(postits.map((obj, index) => {
      if (obj.id === ind) {
        return { ...obj, display: "none" };
      }

      return obj;
    }))
  }

  useEffect(() => {
    if (!salon) {
      checkSalon();

      if (!sessionStorage.getItem('date')) {
        sessionStorage.setItem('date', dateNow("php"));
        setDateActive(new Date(dateNow("php")));
      }
      else {
        setDateActive(new Date(sessionStorage.getItem('date')));
      }

      axios.get("https://connivence-salon.net/api/resaplan/prestas.php")
        .then((res) => {
          if (res.data.success) {
            setPrestas(res.data.content);
          }
          else if (res.data.error) {
            console.log(res.data.error);
          }
        })
    }
    else {
      getDatas();
      let count = 0;
      let loaderInterval = setInterval(() => {
        count++;
        if (count === 2) {
          setIsLoading(false);
          clearInterval(loaderInterval);
        }
      }, 750)
    }

    if (!postitsInitialized) {
      if (localStorage.getItem("postits")) {
        let allPostitsLocal = localStorage.getItem("postits");

        allPostitsLocal = JSON.parse(allPostitsLocal);

        setPostits(allPostitsLocal);
      }
      setPostitsInitalized(true);
    }

  }, [salon])

  return (
    <div id="home" onMouseMove={handlerMove} onMouseUp={handlerMouseUp} onTouchMove={handlerTouchMove}>
      <header>

        {window.matchMedia("(orientation: portrait)").matches ? "" :
          <a href="https://connivence-salon.net/backoffice">
            <FontAwesomeIcon icon={faArrowLeftLong} />
            Connivence {salon?.salon}
          </a>
        }

        <div className='date-container'>
          <h1>
            <button className='arrow-day' onClick={() => shortcutToDay(-1)}>
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            <button onClick={() => setCalendarOpened(true)}>
              <FontAwesomeIcon icon={faCalendarDays} />
              {dateNow("beauty")}
            </button>
            <button className='arrow-day' onClick={() => shortcutToDay(1)}>
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </h1>
        </div>
        <div className='header-actions'>
          <button className='today-shortcut' onClick={goToToday}>
            <FontAwesomeIcon icon={faClockRotateLeft} />
          </button>
          {window.matchMedia("(orientation: portrait)").matches ? "" :
            <button onClick={addPostit}>
              <FontAwesomeIcon icon={faNoteSticky} />
            </button>
          }
          <button onClick={() => setRecurrentsOpened(true)}>
            <FontAwesomeIcon icon={faUserClock} />
          </button>
        </div>

        {
          calendarOpened ?
            <Calendar closeCalendar={closeCalendar} changeDate={changeDate} dateNow={dateNow} />
            :
            ""
        }

        {
          recurrentsOpened ? 
          <Recurrents closeRecurrents={closeRecurrents} salon={salon} />
          :
          ""
        }


      </header>
      <main>
        <ul className='planning-column' onScroll={(ev) => setCalendarScrollTop(ev.target.scrollTop)}>
          <li>
            <ul className='hour-column'>
              <li>

              </li>
              {
                hours.map((hour, index) => (
                  <Hour hour={hour} nowHour={nowHour} scrollToHour={scrollToHour} lastElement={index === hours.length - 1 ? true : false} firstAppointment={firstAppointment} lastAppointment={lastAppointment} key={index} />
                ))
              }
            </ul>

          </li>
          {
            datas?.map((hairdresser, index) => (
              <Column key={index} firstColumn={index === mobileColumnIndex ? true : false} mobileChangeColumn={mobileChangeColumn} resize={resize} isTouchDevice={isTouchDevice} hairdresser={hairdresser} prestas={prestas} hairdressersList={hairdressers} salon={salon} getDatas={getDatas} openNotifScreen={openNotifScreen} setCalPos={setCalPos} getCalPos={getCalPos} calendarScrollTop={calendarScrollTop} firstAppointment={firstAppointment} lastAppointment={lastAppointment} />
            ))
          }
        </ul>
        {
          datas === null || isLoading ?
            <section className='loading-calendar'>
              <span>
                <GridLoader
                  color={'#fff'}
                  loading={true}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </span>
              <p>Chargement du planning...</p>
            </section>
            :
            ""
        }

        {
          notifScreen ?
            <NotifScreen message={notifMessage} closeScreen={closeNotifScreen} />
            :
            ""
        }

        {
          postits.map((postit, index) => (
            <Postit postit={postit} key={index} mousePos={mousePos} touchPos={touchPos} closePostit={closePostit} />
          ))
        }
      </main>
    </div>
  );
};

export default Home;
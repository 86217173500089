import React from 'react';
import Week from './Week';

const Month = ({ month, changeDate, closeCalendar }) => {
  return (
    <article className={month.available ? "" : "past"}>
      <h3>{month?.name}</h3>
      <ul className='calendar'>
        <li>
          <ul className='calendar-row legend'>
            <li className='discreet-text'>L</li>
            <li className='discreet-text'>M</li>
            <li className='discreet-text'>M</li>
            <li className='discreet-text'>J</li>
            <li className='discreet-text'>V</li>
            <li className='discreet-text'>S</li>
            <li className='discreet-text'>D</li>
          </ul>
        </li>
        {
          month?.calendar.map((row, index) => (
            <Week changeDate={changeDate} key={index} week={row} closeCalendar={closeCalendar} />
          ))
        }

      </ul>
    </article>

  );
};

export default Month;
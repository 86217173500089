import React from 'react';

const HairdresserCTA = ({ hairdresser, mobileChangeColumn, hdIndex, handlerClose }) => {

  const handlerClick = () => {
    mobileChangeColumn(hdIndex);
    handlerClose();
  }

  return (
    <li>
      <button date-id={hairdresser.id} onClick={handlerClick}>
        {hairdresser.prenom} {hairdresser.nom[0]}.
      </button>
    </li>
  );
};

export default HairdresserCTA;
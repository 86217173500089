import axios from 'axios';
import React, { useEffect, useState } from 'react';
import UserRecurrent from './UserRecurrent';

const Recurrents = ({ closeRecurrents, salon }) => {
  const [opened, setOpened] = useState(false);
  const [recurrents, setRecurrents] = useState([]);

  const handlerClose = (ev) => {
    if (ev.target.tagName === "SECTION") {
      setOpened(false);

      let timeout = window.setTimeout(() => {
        closeRecurrents();

        return () => {
          clearTimeout(timeout);
        }
      }, 300)
    }

  }

  const getRecurrents = () => {
    axios.get("https://connivence-salon.net/api/resaplan/recurrents.php?salon=" + salon.id)
      .then((res) => {
        if (res.data.success) {
          setRecurrents(res.data.content);
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }
      })
  }

  useEffect(() => {
    let timeout = window.setTimeout(() => {
      setOpened(true);
      return () => {
        clearTimeout(timeout);
      }
    }, 100)

    getRecurrents();
  }, [])


  return (
    <section className={opened ? "recurrents-container visible" : "recurrents-container"} onClick={handlerClose}>
      <div className='recurrents-content'>
        <h2>
          Rendez-vous récurrents
        </h2>
        <ul>
          { recurrents.length > 0 ?
            
            recurrents?.map((recurrent, index) => (
            <UserRecurrent recurrent={recurrent} key={index} getRecurrents={getRecurrents} />
          ))

          :

          <li className='empty'>
            <p>
              Aucun rendez-vous récurrent n'a été programmé pour le moment...
            </p>
          </li>
          }
        </ul>
      </div>
    </section>
  );
};

export default Recurrents;
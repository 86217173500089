import { faArrowLeftLong, faArrowRightLong, faLeftLong, faMinus, faPlus, faScissors } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import Divide from './Divide';
import Result from './Result';

const CreatePopup = ({ closePopup, slot, tranche, prestas, hairdressersList, hd, salon, getDatas, forced, openNotifScreen }) => {

  const [opened, setOpened] = useState(false);
  const [dateDay, setDateDay] = useState();
  const [dateHour, setDateHour] = useState();
  const [step, setStep] = useState(0);
  const [createFormOpened, setCreateFormOpened] = useState(false);
  const [clientsSearch, setClientsSearch] = useState([]);
  const [searchCooldown, setSearchCooldown] = useState(2);
  const [appointDivides, setAppointDivides] = useState([]);
  const [recurrence, setRecurrence] = useState(-1);
  const dayInput = useRef();
  const hourInput = useRef();
  const prestaInput = useRef();
  const hdInput = useRef();
  const prenomInput = useRef();
  const nomInput = useRef();
  const mailInput = useRef();
  const phoneInput = useRef();
  const ncInput = useRef();
  const searchInput = useRef();
  const durationInput = useRef();

  const handlerClose = (ev) => {
    if (ev.target.tagName === "SECTION") {
      setOpened(false);

      let timeout = window.setTimeout(() => {
        closePopup();

        return () => {
          clearTimeout(timeout);
        }
      }, 300)
    }

  }

  const dateFormat = () => {
    axios.get("https://connivence-salon.net/api/resaplan/date.php?day=" + slot.day + "&hour=" + slot.hour + "&tranche=" + tranche)
      .then((res) => {
        if (res.data.success) {
          setDateDay(res.data.content.split(" ")[0]);
          setDateHour(res.data.content.split(" ")[1]);
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }
      })
  }

  const handlerNext = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    setStep(1);
  }

  const handlerBack = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    setStep(0);
    setClientsSearch([]);
    searchInput.current.value = "";
  }

  const handlerOpenCreator = (ev) => {
    ev.preventDefault();
    setCreateFormOpened(true);
    searchInput.current.value = "";
  }

  const handlerCloseCreator = (ev) => {
    ev.preventDefault();
    setCreateFormOpened(false);
    prenomInput.current.value = "";
  }

  const createAppointment = (requestUrl, result) => {
    axios.get("https://connivence-salon.net/api/resaplan/create.php?" + requestUrl)
      .then((res) => {
        if (res.data.success) {
          if (res.data.success === "Slot unavailable") {
            window.alert("Ce créneau n'est pas disponible...");
          }
          else {
            openNotifScreen("Le rendez-vous de " + result.nom.toUpperCase() + " " + result.prenom + " a bien été créé !");
            getDatas();
            closePopup();
          }

        }
        else if (res.data.error) {
          console.log(res.data.error);
        }
      })
  }

  const handlerSubmit = (ev, result) => {
    ev.preventDefault();
    let submitError = false;

    if (dayInput.current.value.length === 0) {
      window.alert("Veuillez renseigner une date de rendez-vous !");
      submitError = true;
    }
    else if (hourInput.current.value.length === 0) {
      window.alert("Veuillez renseigner une heure de rendez-vous !");
      submitError = true;
    }
    else if (result === false) {
      if (prenomInput.current.value.length === 0) {
        window.alert("Veuillez renseigner le prénom du client !");
        submitError = true;
      }
      else if (nomInput.current.value.length === 0) {
        window.alert("Veuillez renseigner le nom du client !");
        submitError = true;
      }
    }

    if (!submitError) {

      let requestUrl;

      let requestDivides = [];

      let arrayRecurrences = [recurrence, 0, 0];

      for (let i = 0; i < appointDivides.length; i++) {
        requestDivides.push(appointDivides[i].join(","));
      }

      if (result === false) {
        requestUrl = "day=" + dayInput.current.value + "&hour=" + hourInput.current.value + "&salon=" + salon.id + "&presta=" + prestaInput.current.value + "&hairdresser=" + hdInput.current.value + "&client=0&tranche=" + hourInput.current.value.split(":")[1] + "&prenom=" + prenomInput.current.value + "&nom=" + nomInput.current.value + "&mail=" + mailInput.current.value + "&telephone=" + phoneInput.current.value + "&nc=" + ncInput.current.checked + "&forced=" + forced + "&divides=" + requestDivides.join("D") + "&recurrence=" + arrayRecurrences.join("R");
        result = { prenom: prenomInput.current.value, nom: nomInput.current.value };
      }
      else {
        requestUrl = "day=" + dayInput.current.value + "&hour=" + hourInput.current.value + "&salon=" + salon.id + "&presta=" + prestaInput.current.value + "&hairdresser=" + hdInput.current.value + "&client=" + result.id + "&tranche=" + hourInput.current.value.split(":")[1] + "&forced=" + forced + "&divides=" + requestDivides.join("D") + "&recurrence=" + arrayRecurrences.join("R") + "&mail=";
      }

      axios.get("https://connivence-salon.net/api/resaplan/availability.php?" + requestUrl)
        .then((res) => {
          if (res.data.success) {
            if (res.data.success === "Slot unavailable") {
              let errorMessage;
              if (parseInt(res.data.errorId) <= 7) {
                errorMessage = "Le rendez-vous sortira des horaires de l'un de vos employés. Voulez-vous continuer ?";
              }
              else {
                errorMessage = "Le rendez-vous débordera sur un autre rendez-vous de l'un de vos employés. Voulez-vous continuer ?";
              }
              if (window.confirm(errorMessage)) {
                createAppointment(requestUrl, result);
              }
            }
            else {
              if (window.confirm("Voulez-vous vraiment enregistrer le rendez-vous de " + result.prenom + " " + result.nom + " ?")) {
                createAppointment(requestUrl, result);
              }
            }

          }
          else if (res.data.error) {
            console.log(res.data.error);
          }
        })

    }

  }

  const setDurationValue = (min) => {
    let thisHour = Math.floor(min / 60);
    thisHour = thisHour < 10 ? "0" + thisHour : thisHour;
    let thisMinute = min % 60;
    thisMinute = thisMinute < 10 ? "0" + thisMinute : thisMinute;
    durationInput.current.value = thisHour + ":" + thisMinute;
  }

  const setMinutesValue = (duration) => {
    let thisHour = parseInt(duration.split(":")[0]);
    let thisMinute = parseInt(duration.split(":")[1]);

    return String(thisHour * 60 + thisMinute);
  }

  const handlerChange = (ev) => {
    let thisPresta = prestas.filter(presta => presta.id === prestaInput.current.value);
    let thisDuree = thisPresta[0].duree;
    if (ev.target === prestaInput.current) {
      setDurationValue(thisDuree);
    }
    setAppointDivides(oldArray => oldArray.map((divide, index) =>
      oldArray.length - 1 === index
        ? [prestaInput.current.value, hdInput.current.value, setMinutesValue(durationInput.current.value)]
        : [...divide]
    ));
  }

  const handlerDuration = (ev) => {
    setAppointDivides(oldArray => oldArray.map((divide, index) =>
      oldArray.length - 1 === index
        ? [prestaInput.current.value, hdInput.current.value, setMinutesValue(durationInput.current.value)]
        : [...divide]
    ));
  }

  const handlerPlus = (ev) => {
    ev.preventDefault();
    setAppointDivides(oldArray => [...oldArray, [prestas[0].id, hd.id, prestas[0].duree]]);
    hdInput.current.value = hd.id;
    prestaInput.current.value = prestas[0].id;
    setDurationValue(prestas[0].duree);
  }

  const handlerMinus = (ev) => {
    ev.preventDefault();
    hdInput.current.value = appointDivides[appointDivides.length - 2][1];
    prestaInput.current.value = appointDivides[appointDivides.length - 2][0];
    let thisPresta = prestas.filter(presta => presta.id === appointDivides[appointDivides.length - 2][0]);
    setDurationValue(thisPresta[0].duree);
    setAppointDivides(oldArray => oldArray.slice(0, -1));
  }

  const handlerRecurrence = (ev) => {
    ev.preventDefault();
    setRecurrence(parseInt(ev.target.dataset.value));
  }

  useEffect(() => {
    if (!opened) {
      let timeout = window.setTimeout(() => {
        setOpened(true);
        dateFormat();

        return () => {
          clearInterval(timeout)
        }
      }, 100)

      setAppointDivides([[prestas[0].id, hd.id, prestas[0].duree]]);

      setDurationValue(prestas[0].duree);
    }

    let interval = window.setInterval(() => {
      if (searchCooldown < 1) {
        setSearchCooldown(searchCooldown + 1);
      }

    }, 1000)

    if (searchCooldown === 1) {
      if (searchInput.current.value === "") {
        setClientsSearch([]);
        setSearchCooldown(2);
      }
      else {
        axios.get("https://connivence-salon.net/api/resaplan/fiches.php?content=" + searchInput.current.value)
          .then((res) => {
            if (res.data.success) {
              setClientsSearch(res.data.content);
              setSearchCooldown(2);
            }
            else if (res.data.error) {
              console.log(res.data.error);
            }
          })
      }

    }

    return () => {
      clearInterval(interval);
    }
  }, [searchCooldown])

  return (
    <section className={opened ? "create-container visible" : "create-container"} onClick={handlerClose}>
      <div className='create-content'>
        <h2>Ajouter un rendez-vous</h2>
        <form className='appoint-form'>
        <ul className='recurrence'>
          <li>
            <button className={recurrence === -1 ? "selected" : ""} data-value="-1" onClick={handlerRecurrence}>Pas de récurrence</button>
          </li>
          <li>
            <button className={recurrence === 0 ? "selected" : ""} data-value="0" onClick={handlerRecurrence}>Hebdomadaire</button>
          </li>
          <li>
            <button className={recurrence === 2 ? "selected" : ""} data-value="2" onClick={handlerRecurrence}>Mensuel</button>
          </li>
        </ul>
          <div className={'steps step-' + step}>
            <div className='step step-form'>
              <h3>Le rendez-vous</h3>
              <div className='form-element'>
                <label htmlFor="create-date">Date et heure du rendez-vous :</label>
                <div className='form-inputs'>
                  <input type="date" id="create-date" name="create-date" defaultValue={dateDay} ref={dayInput} />
                  <input type="time" id="create-hour" name="create-hour" defaultValue={dateHour} step="900" max="23:30" min="05:00" ref={hourInput} />
                </div>
              </div>
              <div className='form-element'>
                <label htmlFor="create-presta">Prestation :</label>
                <select defaultValue="1" id="create-presta" name="create-presta" ref={prestaInput} onChange={handlerChange}>
                  {
                    prestas?.map((presta, index) => (
                      <option key={index} value={presta.id}>{presta.prestation}</option>
                    ))
                  }
                </select>
              </div>
              <div className='form-element'>
                <label htmlFor="create-hairdresser">Coiffeur :</label>
                <select defaultValue={hd.id} id="create-hairdresser" name="create-hairdresser" ref={hdInput} onChange={handlerChange}>
                  {
                    hairdressersList?.map((hairdresser, index) => (
                      <option key={index} value={hairdresser.id}>{hairdresser.prenom} {hairdresser.nom[0]}.</option>
                    ))
                  }
                </select>
              </div>

              <div className='form-element'>
                <label htmlFor="create-duration">Durée de la prestation :</label>
                <div className='form-inputs'>
                  <input type="time" id="create-duration" name="create-duration" defaultValue="00:00" step="900" max="10:00" min="00:00" ref={durationInput} onInput={handlerDuration} />
                </div>
              </div>

              {step === 0 ?

                <div className='form-element btn-divides'>
                  {
                    appointDivides?.length > 1 ?
                      <button onClick={handlerMinus}><FontAwesomeIcon icon={faMinus} /></button>
                      :
                      ""
                  }
                  {
                    appointDivides?.length < 10 ?
                      <button onClick={handlerPlus}><FontAwesomeIcon icon={faPlus} /></button>
                      :
                      ""
                  }
                </div>
                :
                ""
              }

              {step === 0 ?
                <p className='divides-list'>
                  <strong>Récapitulatif : </strong>
                  {appointDivides?.map((divide, index) => (
                    <Divide key={index} presta={prestas.find(x => x.id === divide[0]).prestation} hd={hairdressersList.find(x => x.id === divide[1]).prenom + " " + hairdressersList.find(x => x.id === divide[1]).nom[0] + "."} end={index === appointDivides.length - 1 ? true : false} />

                  ))}
                </p>
                :
                ""
              }

            </div>
            <div className='step step-client'>
              {createFormOpened ?
                <>
                  <h3>Créer une fiche</h3>
                  <div className='form-element'>
                    <label htmlFor="client-prenom">Prénom :</label>
                    <input type="text" id="client-prenom" name="client-prenom" minLength="1" autoComplete="off" ref={prenomInput} required />
                  </div>
                  <div className='form-element'>
                    <label htmlFor="client-nom">Nom :</label>
                    <input type="text" id="client-nom" name="client-nom" minLength="1" autoComplete="off" ref={nomInput} required />
                  </div>
                  <div className='form-element'>
                    <label htmlFor="client-nom">Adresse mail :</label>
                    <input type="email" id="client-mail" name="client-mail" autoComplete="off" pattern=".+@globex\.com" ref={mailInput} />
                  </div>
                  <div className='form-element'>
                    <label htmlFor="client-nom">Téléphone :</label>
                    <input type="text" id="client-telephone" name="client-telephone" autoComplete="off" ref={phoneInput} />
                  </div>
                  <div className='form-element form-checkbox'>
                    <input type="checkbox" id="nc" name="nc" ref={ncInput} defaultChecked />
                    <label htmlFor='nc'>Nouveau client</label>
                  </div>
                </>
                :
                <>
                  <h3>Le client</h3>
                  <div className='form-element'>
                    <label htmlFor="create-search-client">Rechercher un client :</label>
                    <input type="text" id="create-search-client" name="create-search-client" onChange={() => setSearchCooldown(0)} ref={searchInput} />
                  </div>
                  <div className={searchCooldown < 2 ? 'search-box' : 'search-box loading'}>
                    {searchCooldown < 2 ?
                      <div className='loader'>
                        <PulseLoader
                          color={'#191919'}
                          loading={true}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>

                      :
                      <ul>
                        {
                          window.matchMedia("(orientation: portrait)").matches ? 
                          clientsSearch?.slice(0, 5)
                            .map((result, index) => (
                              <Result key={index} result={result} handlerSubmit={handlerSubmit} />
                            ))
                          :
                          clientsSearch?.slice(0, 9)
                            .map((result, index) => (
                              <Result key={index} result={result} handlerSubmit={handlerSubmit} />
                            ))
                        }
                        <li className='add-client'>
                          <button onClick={handlerOpenCreator}>+ Créer une fiche</button>
                        </li>
                      </ul>
                    }
                  </div>
                </>
              }
            </div>
          </div>

          <div className='form-actions'>
            {
              createFormOpened ?
                <>
                  <button className='close-button' onClick={handlerCloseCreator}>
                    <span className='arrow'>
                      <FontAwesomeIcon icon={faArrowLeftLong} />
                    </span>
                    Retour
                  </button>
                  <button className='create-button' onClick={(ev) => handlerSubmit(ev, false)}>
                    Valider
                    <span className='arrow'>
                      <FontAwesomeIcon icon={faScissors} />
                    </span>
                  </button>
                </>
                :
                step === 1 ?
                  <button className='back-button' onClick={handlerBack}>
                    <span className='arrow'>
                      <FontAwesomeIcon icon={faArrowLeftLong} />
                    </span>
                    Précédent
                  </button>
                  :
                  <button className='next-button' onClick={handlerNext}>
                    Suivant
                    <span className='arrow'>
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </span>
                  </button>

            }
          </div>
        </form>
      </div>
    </section>
  );
};

export default CreatePopup;
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
//import { HTML5Backend } from 'react-dnd-html5-backend';
import Home from './pages/Home';
import Login from './pages/Login';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';

function App() {
  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <BrowserRouter>
        <Routes>
          <Route path='/:salonId' element={<Home />} />
          <Route path='/' element={<Login />} />
          <Route path='*' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </DndProvider>
  );
}

export default App;

import { faScissors } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Result = ({ result, handlerSubmit }) => {
  return (
    <li>
      <button onClick={(ev) => handlerSubmit(ev, result)}>
        {result.prenom} {result.nom}
      </button>
    </li>
  );
};

export default Result;
import React, { useEffect, useRef, useState } from 'react';
//import { useDrag } from 'react-dnd';
import { useMultiDrag } from 'react-dnd-multi-backend';
import EditPopup from './EditPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

const Appoints = ({ appoint, prestas, hairdressersList, getDatas, salon, openNotifScreen, isTouchDevice, resize }) => {

  const [[dragProps], { html5: [html5Props, dragRefHTML], touch: [touchProps, dragRefMobile] }] = useMultiDrag({
    type: 'appoint',
    item: { appoint },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: (monitor) => {
      if (isResizing) {
        return false;
      }
      else {
        if (window.matchMedia("(orientation: portrait)").matches) {
          return false;
        }
        else{
          return true;
        }
        
      }
    }
  })

  const date = new Date(appoint.date);

  const [popupOpened, setPopupOpened] = useState(false);
  const [classQuarter, setClassQuarter] = useState("");
  const [isResizing, setIsResizing] = useState(false);

  const resizeBlock = useRef();

  const closePopup = () => {
    setPopupOpened(false);
  }

  const handlerSizeChangeBegin = (ev) => {
    setIsResizing(true);
    resize(ev, appoint);

  }

  useEffect(() => {
    if (classQuarter === "") {
      if (date.getMinutes() === 15) {
        setClassQuarter(" one-quarter");
      }
      else if (date.getMinutes() === 30) {
        setClassQuarter(" mid");
      }
      else if (date.getMinutes() === 45) {
        setClassQuarter(" three-quarter");
      }
      else if (date.getMinutes() === 0) {
        setClassQuarter(" full");
      }
    }

  }, [])

  return (
    <>
      <div id={'appoint-' + appoint.rdv_id} className={"appoint" + classQuarter + " type-" + prestas[parseInt(appoint.presta_id) - 1]?.type + " drag-box"} style={{ height: "calc(" + 100 / 60 * appoint.custom + "% + " + (appoint.custom / 60) * 2 + "px - 2px)" }} onClick={(ev) => ev.target.className !== 'change-size-bottom' && isResizing === false ? setPopupOpened(true) : ""} ref={(el) => { dragRefHTML(el); dragRefMobile(el); }} data-duration={appoint.custom}>
        {
          <p className={(appoint.custom !== null && appoint.custom > 30) ? "visible" : "hidden"}>{prestas[parseInt(appoint.presta_id) - 1]?.prestation}</p>
        }
        <h2 className={(appoint.custom !== null && appoint.custom > 30) ? "ceiling" : "quirky"}>{appoint.nom.toUpperCase()} {appoint.prenom}</h2>
        <p className='appoint-hour'>
            {appoint.parent ? "" : 
              <span className='crown'>
                <FontAwesomeIcon icon={faCrown} />
              </span>
            }
          {appoint.date.split(" ")[1].split(":")[0]}:{date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}
        </p>
        <div className='change-size-bottom' onMouseDown={handlerSizeChangeBegin} ref={resizeBlock}></div>
      </div>
      {
        popupOpened ?
          <EditPopup appoint={appoint} closePopup={closePopup} hairdressersList={hairdressersList} prestas={prestas} getDatas={getDatas} salon={salon} openNotifScreen={openNotifScreen} />
          :
          ""
      }
    </>
  );
};

export default Appoints;
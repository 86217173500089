import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect } from 'react';

const UserRecurrent = ({ recurrent, getRecurrents }) => {

  const temporalities = ["Hebdomadaires", "Bihebdomadaires", "Mensuels"]

  const handlerRemove = (ev) => {
    ev.preventDefault();
    if (window.confirm("Voulez-vous vraiment supprimer la récurrence de " + recurrent.fiche.prenom + " " + recurrent.fiche.nom + " ?")) {
      axios.get("https://connivence-salon.net/api/resaplan/remove_recurrent.php?id=" + recurrent.id)
        .then((res) => {
          if (res.data.success) {
            getRecurrents();
          }
          else if (res.data.error) {
            console.log(res.data.error);
          }
        })
    }
  }

  useEffect(() => {
    console.log(recurrent)
  }, [])
  
  return (
    <li>
      <span>
        <strong>{recurrent.fiche.nom.toUpperCase()} {recurrent.fiche.prenom}</strong>
      </span>
      <span>
        <p>
        {
          temporalities[parseInt(recurrent.temporality)]
        }
        </p>
      </span>
      <button onClick={handlerRemove}>
        <FontAwesomeIcon icon={faXmark} />
        <strong>Supprimer</strong>
      </button>
    </li>
  );
};

export default UserRecurrent;
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useMultiDrop } from 'react-dnd-multi-backend';
import Appoints from './Appoints';
import CreatePopup from './CreatePopup';


const Row = ({ slot, last, next, prestas, hairdressersList, hairdresser, salon, getDatas, openNotifScreen, isTouchDevice, resize, setCalPos, getCalPos, firstAppointment, lastAppointment }) => {

  const types = [
    "Salon",
    "Ecole",
    "Stage",
    "Vacances",
    "Arret",
    "HS"
  ]

  const [available, setAvailable] = useState([false, false]);
  const [type, setType] = useState(types[slot.ecole]);
  const [appoints, setAppoints] = useState([]);
  const [popupOpened, setPopupOpened] = useState(false);
  const [tranche, setTranche] = useState("00");
  const [touchChrono, setTouchChrono] = useState({});
  const [forced, setForced] = useState(0);

  const dropRefLi = useRef();

  const [[dropPropsFull], { html5: [html5FullDropStyle, dropRefFullHTML], touch: [touchFullDropStyle, dropRefFullMobile] }] = useMultiDrop({
    accept: 'appoint',
    hover: (item, monitor) => handlerDragHover(item, monitor, "00"),
    drop: (item) => handlerDropEdit(item, "00"),
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  })
  const [[dropPropsMid], { html5: [html5MidDropStyle, dropRefMidHTML], touch: [touchMidDropStyle, dropRefMidMobile] }] = useMultiDrop({
    accept: 'appoint',
    hover: (item, monitor) => handlerDragHover(item, monitor, "30"),
    drop: (item) => handlerDropEdit(item, "30"),
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  })

  const forcing = (target) => {
    if (window.confirm("Voulez-vous vraiment ajouter un rendez-vous sur ce créneau ?")) {
      setPopupOpened(true);
      setTranche(target.dataset.tranche);
      setForced(1);
    }
  }

  const handlerClick = (ev) => {
    document.querySelector(".drag-focused")?.classList.remove("drag-focused")
    if (ev.target.classList.contains("slot-salon") && ev.target.classList.contains("available")) {
      setPopupOpened(true);
      setTranche(ev.target.dataset.tranche);
    }
    else {
      if (ev.detail === 2) {
        forcing(ev.target);
      }
    }
  }
  const handlerTouchStart = (ev) => {
    setCalPos(document.querySelector(".planning-column").scrollTop);
    if (!(ev.target.classList.contains("slot-salon") && ev.target.classList.contains("available"))) {
      let thisChrono = setInterval(() => {
        if (document.querySelector(".planning-column").scrollTop - getCalPos() < 40 && document.querySelector(".planning-column").scrollTop - getCalPos() > -40) {
          forcing(ev.target);
        }
        clearInterval(thisChrono)
        clearInterval(touchChrono.thisChrono)
      }, 800)

      setTouchChrono({ thisChrono });
    }

  }

  const handlerTouchEnd = (ev) => {
    setCalPos(document.querySelector(".planning-column").scrollTop);
    clearInterval(touchChrono.thisChrono);
  }

  const closePopup = () => {
    setForced(0);
    setPopupOpened(false);
  }

  const getAppoints = () => {
    if (slot.appoints.length > 0) {
      let appointTmp = slot.appoints[0];
      let minutesTmp = 0;
      for (let i = 0; i < slot.appoints.length; i++) {
        slot.appoints[i].minutes = parseFloat(parseInt(slot.appoints[i].duree) / 60);
        if (slot.appoints[i].custom !== null) {
          slot.appoints[i].custom = parseFloat(slot.appoints[i].custom);
        }
        setAppoints(oldArray => (
          [...oldArray, slot.appoints[i]]
        ));
        //minutesTmp += parseFloat(slot.appoints[i].minutes);
      }

      //appointTmp.minutes = parseFloat(minutesTmp);

      //setAppoints(appointTmp);
    }
  }

  const handlerDropEdit = (item, tranche) => {
    axios.get("https://connivence-salon.net/api/resaplan/remove.php?id=" + item.appoint.rdv_id + "&editing=1")
      .then((res) => {
        if (res.data.success) {
          let ficheId = res.data.success.fiche;
          item.appoint.divides[0][1] = hairdresser.id;

          let requestDivides = [];

          for (let i = 0; i < item.appoint.divides.length; i++) {
            requestDivides.push(item.appoint.divides[i].join(","));
          }

          let parent = item.appoint.parent === null ? 0 : item.appoint.parent;

          //dateFormat();

          let thisHour = slot.full_hour + ":" + tranche + ":00";
          let requestUrl = "day=" + slot.full_day + "&hour=" + thisHour + "&salon=" + salon.id + "&presta=" + item.appoint.presta_id + "&hairdresser=" + hairdresser.id + "&client=" + ficheId + "&tranche=" + tranche + "&divides=" + requestDivides.join("D") + "&recurrence=" + item.appoint.recurrent.join("R") + "&custom=" + item.appoint.custom + "&parent=" + parent + "&editing=1&forced=0&mail=";
          axios.get("https://connivence-salon.net/api/resaplan/create.php?" + requestUrl)
            .then((resBis) => {
              if (resBis.data.success) {
                if (resBis.data.success === "Slot unavailable") {

                }
                else {
                  getDatas();
                }

              }
              else if (resBis.data.error) {
                console.log(resBis.data.error);
              }
            })
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }
      })
  }

  const handlerDragHover = (item, monitor, tranche) => {
    if (monitor.isOver()) {
      if (tranche === "00") {
        document.querySelector(".drag-focused")?.classList.remove("drag-focused")
        dropRefLi.current.querySelectorAll(".planning-subrow")[0].classList.add("drag-focused");
      }
      else if (tranche === "30") {
        document.querySelector(".drag-focused")?.classList.remove("drag-focused")
        dropRefLi.current.querySelectorAll(".planning-subrow")[1].classList.add("drag-focused");
      }
    }
    else {
      document.querySelector(".drag-focused")?.classList.remove("drag-focused")
    }
  }

  useEffect(() => {


    if (slot.nb === "1") {
      setAvailable([true, true]);
    }
    else if (slot.nb === "0.5") {
      if ((last && parseFloat(last) > 0) && (next && parseFloat(next) > 0)) {
        setAvailable([true, true]);
      }
      else {
        if (last && parseFloat(last) > 0) {
          setAvailable([true, false]);
        }
        else if (next && parseFloat(next) > 0) {
          setAvailable([false, true]);
        }
      }

    }
    else {
      setAvailable([false, false]);
    }

    getAppoints();
  }, [slot, last, next])
  return (
    <li ref={dropRefLi} className='planning-subrow-parent' style={parseInt(slot.full_hour) < firstAppointment || parseInt(slot.full_hour) > lastAppointment ? { display: "none" } : {}}>
      <div className={available[0] ? "available drop-board planning-subrow slot-" + type.toLowerCase() : "planning-subrow drop-board slot-" + type.toLowerCase()} onClick={handlerClick} onTouchStart={handlerTouchStart} onTouchEnd={handlerTouchEnd} data-tranche="00" ref={(el) => { dropRefFullHTML(el); dropRefFullMobile(el); }}>
        {
          type !== "Salon" && type !== "HS" ?
            <p className='slot-type'>{type}</p>
            :
            ""
        }
      </div>
      <div className={available[1] ? "available drop-board planning-subrow slot-" + type.toLowerCase() : "planning-subrow drop-board slot-" + type.toLowerCase()} onClick={handlerClick} onTouchStart={handlerTouchStart} onTouchEnd={handlerTouchEnd} data-tranche="30" ref={(el) => { dropRefMidHTML(el); dropRefMidMobile(el); }}>
        {
          type !== "Salon" && type !== "HS" ?
            <p className='slot-type'>{type}</p>
            :
            ""
        }
      </div>
      {
        appoints?.map((appoint, index) =>
          <Appoints key={index} resize={resize} isTouchDevice={isTouchDevice} appoint={appoint} prestas={prestas} hairdressersList={hairdressersList} getDatas={getDatas} salon={salon} openNotifScreen={openNotifScreen} />
        )
      }

      {
        popupOpened ?
          <CreatePopup closePopup={closePopup} slot={slot} tranche={tranche} prestas={prestas} hairdressersList={hairdressersList} hd={hairdresser} salon={salon} getDatas={getDatas} forced={forced} openNotifScreen={openNotifScreen} />
          :
          ""
      }
    </li>
  );
};

export default Row;
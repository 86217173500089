import React, { useEffect, useRef, useState } from 'react';
import Row from './Row';
import HairdressersList from './HairdressersList';

const Column = ({ hairdresser, firstColumn, prestas, hairdressersList, salon, getDatas, openNotifScreen, isTouchDevice, resize, setCalPos, getCalPos, calendarScrollTop, mobileChangeColumn, firstAppointment, lastAppointment }) => {

  const [nfWidth, setNfWidth] = useState();
  const [invisible, setInvisible] = useState(true);
  const [popupOpened, setPopupOpened] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const thisPlanningRow = useRef();
  const name = useRef();

  const closePopup = () => {
    setPopupOpened(false);
  }

  const handlerClick = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setPopupOpened(true);
    }
  }

  const resetNfWidth = () => {
    setNfWidth(thisPlanningRow.current?.offsetWidth / window.innerWidth * 100);
  }

  useEffect(() => {
    resetNfWidth();
    if (calendarScrollTop <= name.current?.offsetHeight) {
      setInvisible(true);
    }
    else {
      setInvisible(false);
    }

    if (!isLoaded) {
      window.addEventListener('resize', () => {
        resetNfWidth();
      })
      setIsLoaded(true);
    }
  }, [calendarScrollTop, firstColumn])

  return (
    <li className={firstColumn ? "mobile-visible" : ""}>
      <ul className='planning-row' ref={thisPlanningRow}>
        <li className='planning-name' ref={name}>
          <h3 onClick={handlerClick}>
            {hairdresser.prenom} {hairdresser.nom[0]}.
          </h3>
        </li>
        <li className={invisible ? 'invisible planning-name-fixed' : 'planning-name-fixed'} style={{ width: "calc(" + nfWidth + "vw)" }} onClick={handlerClick}>
          <p>
            {hairdresser.prenom} {hairdresser.nom[0]}.
          </p>
        </li>
        {
          hairdresser.slots.map((slot, index) => (
            <Row key={index} resize={resize} isTouchDevice={isTouchDevice} getDatas={getDatas} salon={salon} hairdresser={hairdresser} hairdressersList={hairdressersList} prestas={prestas} slot={slot} last={index - 1 > 0 ? hairdresser.slots[index - 1].nb : null} next={hairdresser.slots[index + 1] ? hairdresser.slots[index + 1].nb : null} openNotifScreen={openNotifScreen} setCalPos={setCalPos} getCalPos={getCalPos} firstAppointment={firstAppointment} lastAppointment={lastAppointment} />
          ))
        }
      </ul>
      {
        popupOpened ? <HairdressersList hairdressersList={hairdressersList} closePopup={closePopup} mobileChangeColumn={mobileChangeColumn} resetNfWidth={resetNfWidth} /> : ""
      }

    </li>
  );
};

export default Column;
import React from 'react';

const Login = () => {
  return (
    <section id="login">
      <article>

        <h1>
          <img src='assets/images/logo_hub.png' alt='Logo du HUB' />
        </h1>

        <p>Impossible de se connecter au planning d'un salon ! Il faut vous connecter à un compte HUB en cliquant sur le bouton ci-dessous :</p>

        <a href='https://connivence-salon.net/backoffice/planlogin' >Me connecter</a>

      </article>
    </section>

  );
};

export default Login;
import React from 'react';

const Day = ({ day, changeDate, closeCalendar }) => {

  const handlerSelect = (ev) => {
    if (ev.target.dataset.date !== "0") {
      changeDate(ev.target.dataset.date);
      closeCalendar();
    }

  }
  return (
    <li className={day.day !== "0" ? "" : "empty-day"}>
      <button className='calendar-button' data-date={day.date} onClick={handlerSelect}>{day.day}</button>
    </li>
  );
};

export default Day;